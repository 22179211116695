<template>
  <page-footer>
    <template v-slot:right>
      <!-- <price-fetcher :values="values" :cloudAccountId="cloudAccountId" /> -->
      <div class="d-flex align-items-center">
        <div v-if="price.type === 'new' && price.eipPrice" class="mr-4 d-flex align-items-center">
          <div v-if="price.charge_type === 'traffic'" class="text-truncate">流量</div>
          <div v-else class="text-truncate">带宽峰值</div>
          <div class="ml-2 prices">
            <div class="hour d-flex">
              <div>{{ price.eipPrice }}</div>
            </div>
          </div>
        </div>
        <div v-if="price.type === 'new' && price.eipServerPrice" class="mr-4 d-flex align-items-center">
          <div class="text-truncate">eip服务费</div>
          <div class="ml-2 prices">
            <div class="hour d-flex">
              <div>{{ price.eipServerPrice }}</div>
            </div>
          </div>
        </div>
        <div v-if="price.instance" class="mr-4 d-flex align-items-center">
          <div class="text-truncate">NAT网关实例费</div>
          <div class="ml-2 prices">
            <div class="hour d-flex">
              <div>{{ price.instance }}</div>
            </div>
          </div>
        </div>
        <div v-if="price.cu" class="mr-4 d-flex align-items-center">
          <div class="text-truncate">NAT网关CU费</div>
          <div class="ml-2 prices">
            <div class="hour d-flex">
              <div>{{ price.cu }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns-wrapper d-flex align-items-center">
        <a-button
          class="ml-3"
          type="primary"
          native-type="submit"
          html-type="submit"
          @click="handleConfirm"
          :loading="loading">{{ $t("common_258") }}</a-button>
      </div>
    </template>
  </page-footer>
</template>

<script>
import { mapGetters } from 'vuex'
// import PriceFetcher from '@/components/PriceFetcher'
import { numerify } from '@/filters'
import { CHARGE_TYPE } from '@Network/constants'
import { HYPERVISORS_MAP } from '@/constants'
export default {
  name: 'BottomBar',
  components: {
    // PriceFetcher,
  },
  inject: ['form', 'cloudEnv'],
  props: {
    values: {
      type: Object,
    },
    cloudAccountId: String,
  },
  data () {
    return {
      loading: false,
      price: {
        type: null,
        charge_type: null,
        instance: null,
        cu: null,
        eipPrice: null,
        eipServerPrice: null,
      },
      curPrice: {
        nat: null,
        eip: null,
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    doCreate (data) {
      return new this.$Manager('natgateways').create({ data })
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.form.fc.validateFields()
        const params = {
          project_domain: values.project_domain,
          name: values.name,
          description: values.description,
          area_mapping_id: values.cloudregion,
          replace_list_key: 'cloudregion_id',
          network_id: values.network,
          vpc_id: values.vpc,
          nat_spec: values.sku.name,
          billing_type: values.billing_type,
          __meta__: values.__meta__,
        }
        params.cur_price = this.curPrice
        if (params.billing_type === 'postpaid') {
          if (values.durationStandard !== 'none') {
            params.duration = values.durationStandard
            if (values.durationStandard === 'custom') {
              params.duration = values.duration
            }
          }
        } else {
          params.duration = values.duration
        }
        if (values.type !== 'none') {
          // 防止bandwidth为空
          values.bandwidth = values.bandwidth || 1
        }
        if (values.bandwidth > 0 && values.type !== 'none') {
          params.eip_bw = values.bandwidth
        }
        if (values.charge_type && values.type !== 'none') {
          params.eip_charge_type = values.charge_type
        }
        await this.doCreate(params)
        this.loading = false
        this.$message.success(this.$t('network.nat.create.success'))
        this.$router.push('/nat')
      } catch (error) {
        this.loading = false
        throw error
      }
    },
    checkValues (values) {
      let flag = true
      const { cloudregion, type, bandwidth, charge_type } = values
      if (!cloudregion) {
        flag = false
      }
      if (type === 'new') {
        if (!bandwidth || !charge_type) {
          flag = false
        }
      }
      return flag
    },
    getPrice (values) {
      if (this.checkValues(values)) {
        this.getNATPrice(values)
        this.getEipPrice(values)
      }
    },

    priceFormat1 (currency, price, unit) {
      const str = price.toString()
      const ary = str.split('.')
      let len = 0
      if (ary.length > 1) {
        len = ary[1].length
      }
      let u = '0,0.00'
      if (len > 2) {
        for (let i = 0; i < len - 2; i++) {
          u += '0'
        }
      }
      price = numerify(price, u)

      return `${currency} ${price}${unit}`
    },
    getNATPrice (values) {
      // console.log('getNATPrice values:', values)

      if (!values.sku) {
        return
      }
      const params = {
        billing_type: values.billing_type,
        spec: values?.sku?.name,
        area_mapping_id: values.cloudregion,
        replace_list_key: 'provider,cloudregion',
      }
      new this.$Manager('natgateway_prices/nat-gateway-price', 'v2')
        .list({
          params: params,
        })
        .then((res) => {
          const price = res.data
          // this.$emit('curPrice', price)
          this.curPrice.nat = price
          if (!res.data.instance) {
            return
          }
          this.price.instance = this.priceFormat1(
            '￥',
            res.data.instance,
            '/小时',
          )
          this.price.cu = this.priceFormat1('￥', res.data.cu, '/CU')
          // 移动云不需要展示CU
          if (values?.sku?.provider && HYPERVISORS_MAP.mgecloud.key === values?.sku?.provider.toLowerCase()) {
            this.price.cu = null
          }
        })
    },
    // 获取eip的总价明细展示
    async getEipPrice (values) {
      // 选项是暂不需要时，不请求eip价格接口
      this.price.type = values.type
      this.price.eipPrice = null
      this.price.eipServerPrice = null
      if (values?.type === 'none') return
      const manager = new this.$Manager('price_eips/price', 'v2')
      const { data = {} } = await manager.list({
        params: {
          area_mapping_id: values.cloudregion,
          bandwidth: values.bandwidth,
          type: values.charge_type,
        },
      })
      this.curPrice.eip = data
      this.price.charge_type = values.charge_type
      let eipPriceUnit = '/GB'
      let eipServerPriceUnit = '/小时'
      let eipServerPrice = data.server_price_hour
      let price = data.traffic_price
      // 按带宽计费
      if (this.price.charge_type === CHARGE_TYPE.bandwidth) {
        eipPriceUnit = '/天'
        eipServerPriceUnit = '/天'
        eipServerPrice = data.server_price_day
        price = data.bandwidth_price_day
      }
      this.price.eipPrice = this.priceFormat1('￥', price, eipPriceUnit)
      this.price.eipServerPrice = this.priceFormat1('￥', eipServerPrice, eipServerPriceUnit)
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../../../../../src/styles/less/theme";

.prices {
  .hour {
    color: @error-color;
    font-size: 24px;
  }
  .tips {
    color: #999;
    font-size: 12px;
  }
}
</style>
